import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a `}<inlineCode parentName="p">{`LinkText`}</inlineCode>{` to take a user to another destination outside the current
context.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <p>{`There are two types of LinkTexts: default and branded.`}</p>
    <h3 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}</h3>
    <p>{`Use it for system related links like in e.g. login/registration flows. It always
has a default appearance with a `}<inlineCode parentName="p">{`color-blue-base`}</inlineCode>{` color.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor wrap=flex",
        "hideEditor": true,
        "wrap": "flex"
      }}>{`<Paragraph color="colorGrey80">
  Read
  <LinkText href="http://chameleon.mediahuis.be">terms and conditions</LinkText>.
</Paragraph>
`}</code></pre>
    <h3 {...{
      "id": "branded",
      "style": {
        "position": "relative"
      }
    }}>{`Branded`}</h3>
    <p>{`A branded LinkText is used in stand-alone context.
As the name already implies, the default appearance inherits a brand's primary
or secondary font family and color.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor wrap=flex",
        "hideEditor": true,
        "wrap": "flex"
      }}>{`<Paragraph fontFamily="primary">
  A
  <LinkText href="#" branded>
    recent manuscript
  </LinkText>{' '}
  from the University Medical Center (UMC) of Utrecht makes it very concrete how
  important it is not to waste time. (The article, written by a group of
  experienced modellers including Mirjam Kretzschmar, has not yet been
  peer-reviewed.)
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "linktext-vs-button",
      "style": {
        "position": "relative"
      }
    }}>{`LinkText vs. Button`}</h3>
    <p>{`A LinkText is used primarily for navigation, and usually displayed within
sentences or as stand-alone text. For actions that are related to CRUD (Create,
Read, Update and Delete) views, use a `}<a parentName="p" {...{
        "href": "/components/button/code"
      }}>{`Button`}</a>{`
component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "hideEditor",
        "hideEditor": true
      }}>{`<Paper elevation={2} px={4} py={6}>
  <Box display="flex" flexDirection="column" justifyContent="center">
    <Heading level="3" textAlign="center">
      Read this plus article?
    </Heading>
    <Button mt={4} appearance="primary" width="auto">
      Login to start reading
    </Button>
  </Box>
  <Divider my={5} color="grey30" />
  <Caption textAlign="center">
    Need help? <LinkText>Contact our customer service</LinkText>.
  </Caption>
</Paper>
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related Components`}</h2>
    <ul>
      <li parentName="ul">{`For actions that are not displayed within sentences, use the
`}<a parentName="li" {...{
          "href": "/components/button/code"
        }}>{`Button`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      